import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-checkbox-renderer',
  template: `
    <div class="checkbox-container">
      <input
        type="checkbox"
        [checked]="isChecked"
        (change)="onCheckboxChange($event)"
        [disabled]="isDisabled"
        class="custom-checkbox"
        title="Select"
      />
      <span class="cell-value">{{ params.value }}</span>
    </div>
  `,
  styles: [
    `
      .checkbox-container {
        display: flex;
        align-items: center;
      }

      /* Initial unchecked state styling */
      .custom-checkbox {
        width: 16px;
        height: 16px;
        cursor: pointer;
        border: 1px solid #888888;
        background-color: var(--ag-background-color, #f0f0f0);
        border-radius: 3px;
        margin-right: 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
      }

      /* Add tick using ::after when checkbox is checked */
      .custom-checkbox:checked::after {
        content: '';
        position: absolute;
        width: 4px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        top: 2px;
        left: 5px;
      }

      /* Checked state with a background color */
      .custom-checkbox:checked {
        background-color: var(--primary-color, #004f6f); /* Set the primary background color */
        border-color: var(--primary-color, #004f6f); /* Change border color */
      }

      /* Disabled state with reduced opacity */
      .custom-checkbox:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .cell-value {
        flex-grow: 1;
      }
    `,
  ],
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  public isChecked = false;

  public isDisabled = false;

  tableColumns: any; // Add interface later

  statusFromColDef: any;

  public params!: ICellRendererParams;

  // Initialize the component with the params
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.tableColumns = params.columnApi.getColumns();
    this.statusFromColDef = this.tableColumns.find(
      (col: any) => col.colDef.headerName === 'Status' // Matching and  use another unique property like colDef.field
    );
    this.updateCheckboxState(params);
  }

  // Refresh method when the grid updates
  refresh(params: ICellRendererParams): boolean {
    this.updateCheckboxState(params);

    return true;
  }

  // Called when checkbox value changes
  onCheckboxChange(event: Event): void {
    const checkboxChecked = (event.target as HTMLInputElement).checked;

    // If the checkbox is not disabled, set the selected state of the row
    if (!this.isDisabled && this.params) {
      this.params.node.setSelected(checkboxChecked);
    }
  }

  // Determines if the checkbox should be checked and/or disabled
  private updateCheckboxState(params: ICellRendererParams): void {
    // Ensure isChecked is updated based on row selection state
    this.isChecked = !!params.node.isSelected();

    // Check if the checkbox should be disabled (e.g., based on row data's status)
    this.isDisabled = this.shouldDisableCheckbox(params);
  }

  // Determines whether the checkbox should be disabled based on specific row data
  private shouldDisableCheckbox(rowData: any): boolean {
    // Return true if the rowData's metricversionstatus is 'Revoked', otherwise false
    return rowData.data[this.statusFromColDef.colDef.id] === 'Revoked';
  }
}
