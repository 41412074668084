import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

import { IViewContractData } from '@app/slm/interface/view-contrcat';
import { ContractPermissionService } from '@app/slm/services/contract-permission.service';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-view-contract',
  templateUrl: './view-contract.component.html',
  styleUrls: ['./view-contract.component.scss'],
})
export class ViewContractComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<{ isOpenDuplicateContrcat: boolean; contrcatData?: IViewContractData }>();

  @ViewChild('op', { static: false }) overlayPanel: any;

  @ViewChild('paginationReset') paginationReset!: Table;

  contractSubscription!: Subscription;

  addMetricSubscription!: Subscription;

  versionForm!: FormGroup;

  viewData: any;

  viewVersionList: any = [];

  selectedVersion: any;

  filteredVersion: any = [];

  isLoading = true;

  statusList: any = [
    { label: 'Draft', class: 'warning-chip' },
    { label: 'Published', class: 'success-chip' },
    { label: 'Revoked', class: 'danger-chip' },
  ];

  selectedStatusFilter: any = { Draft: false, Published: false, Revoked: false };

  isPopupVisible = false;

  constructor(
    private contractService: ContractService,
    private formBuilder: FormBuilder,
    public contractPermission: ContractPermissionService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  getViewContract(contractId: number): void {
    this.contractService.getContractByID(contractId).subscribe((res: any) => {
      if (res) {
        this.viewData = res;
        this.viewVersionList = this.viewData.contractVersionList;
        this.patchValue(this.viewVersionList[0]);

        [this.selectedVersion] = this.viewVersionList;

        this.filteredVersion = this.viewVersionList;
        this.cdr.detectChanges(); // Trigger change detection
        // Object.keys(this.viewData).forEach(key => {
        //   this.viewData[key] = this.viewData[key] ? this.viewData[key] : 'N/A';
        // });
      }
    });
  }

  patchValue(data: any): void {
    this.versionForm.patchValue({
      versionName: data.version ? data.version : 'N/A',
      status: data.status ? data.status : 'N/A',
      startDate: data.startDate ? new Date(data.startDate) : 'N/A',
      endDate: data.endDate ? new Date(data.endDate) : 'N/A',
      statement: data.statement ? data.statement : 'N/A',
    });
  }

  applyStatusFilter(event: any, field: string): void {
    Object.keys(this.selectedStatusFilter).forEach(key => {
      if (key === field) {
        this.selectedStatusFilter[key] = event.checked;
      }
    });

    // filter the versions from the list
    const filterList: any = [];

    this.paginationReset?.reset();

    this.viewVersionList.forEach((status: any) => {
      if (this.selectedStatusFilter[status.status]) {
        filterList.push(status);
      }
    });

    // check if no filter selected show initial list
    const noFilterSelected = Object.values(this.selectedStatusFilter).every(v => !v);

    if (noFilterSelected) {
      this.filteredVersion = this.viewVersionList;
    } else {
      this.filteredVersion = filterList;
    }
  }

  onClosePanel(event: any): void {
    this.selectedVersion = event.data;
    this.patchValue(this.selectedVersion);
    this.overlayPanel.hide();
  }

  closeViewContract(): void {
    this.viewData = [];
    this.viewVersionList = [];
    this.versionForm.reset();
    this.closeClicked.emit({ isOpenDuplicateContrcat: false });
  }

  ngOnInit(): void {
    this.versionForm = this.formBuilder.group({
      versionName: [''],
      status: [''],
      startDate: [''],
      endDate: [''],
      statement: [''],
      statusFilter: new FormArray([]),
    });

    this.addCheckboxes();

    this.contractSubscription = this.contractService.viewData.subscribe((res: any) => {
      if (res) {
        this.viewData = [];
        this.isLoading = false;
        this.viewVersionList = [];
        this.getViewContract(res);
      }
    });

    this.addMetricSubscription = this.contractService.selectedMetricList.subscribe((res: any) => {
      if (res?.metricCatalogIds?.length && this.viewData?.id) {
        this.getViewContract(this.viewData?.id);
      }
    });
  }

  openDuplicateContrcat(): void {
    this.closeClicked.emit({ isOpenDuplicateContrcat: true, contrcatData: this.viewData });
  }

  isDataLoading(): boolean {
    return this.isLoading;
  }

  getDisplayValue(value: any): string {
    // Check for null, undefined, or empty string and return 'N/A'
    if (value === null || value === undefined || value === '') {
      return 'N/A';
    }

    // Return as a string if value is not null or undefined
    return value;
  }

  redirectToMetrics(): void {
    this.contractService.getCountOfMetric(this.viewData);
    this.router.navigateByUrl('/slm/metrics');
  }

  ngOnDestroy(): void {
    this.contractSubscription.unsubscribe();
    this.addMetricSubscription.unsubscribe();
  }

  private addCheckboxes(): void {
    this.statusList.map(() => {
      const control = new FormControl();

      return (this.versionForm.get('statusFilter') as FormArray).push(control);
    });
  }
}
