import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';

import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-select-renderer',
  template: `
    <select
      [(ngModel)]="currentValue"
      (change)="onSelectionChange($event)"
      [disabled]="isDisabled"
      class="custom-select"
      aria-label="Select an option"
    >
      <option *ngFor="let option of options" [value]="option">{{ option }}</option>
    </select>
  `,
  styles: [
    `
      .custom-select {
        width: 100%;
        padding: 3px;
        border: 1px solid var(--ag-cell-horizontal-border, #ccc);
        border-radius: 5px;
        font-size: 12px;
        background-color: var(--ag-background-color, white);
        color: var(--text-color);
        box-shadow: var(--ag-cell-shadow, none);
        font-family: var(--font-family);
        transition: border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease;
      }

      .custom-select:hover,
      .custom-select:focus {
        color: rgba(0, 0, 0, 0.87); // Hover/Focus text color
        background-color: rgba(0, 0, 0, 0.04); // Hover/Focus background
        border-color: transparent; // Focus border color
        outline: none;
      }
      /* Styles when the select element is disabled */
      .custom-select:disabled {
        opacity: 0.5;
        background-color: var(--ag-background-color, white);
        color: var(--text-color, #999);
        border-color: var(--ag-cell-horizontal-border, #ccc);
      }
    `,
  ],
  standalone: true,
  imports: [FormsModule, CommonModule],
})
export class SelectRendererComponent implements ICellRendererAngularComp, OnDestroy {
  public options: string[] = [];

  public currentValue = '';

  public isDisabled = false;

  public textColor = 'inherit';

  public borderColor = '#ccc';

  tableColumns: any; // Add interface later

  statusFromColDef: any;

  private params: any;

  private bulkUpdateSubscription: Subscription | null = null;

  constructor(private contractService: ContractService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.tableColumns = params.columnApi.getColumns();
    this.statusFromColDef = this.tableColumns.find(
      (col: any) => col.colDef.headerName === 'Status' // Matching and  use another unique property like colDef.field
    );

    this.updateRenderer(params);

    this.bulkUpdateSubscription = this.contractService.isBulkUpdate$.subscribe((bulkSelector: string) => {
      this.handleBulkUpdate(bulkSelector);
    });
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateRenderer(params);

    return true;
  }

  ngOnDestroy(): void {
    if (this.bulkUpdateSubscription) {
      this.bulkUpdateSubscription.unsubscribe();
    }
  }

  onSelectionChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    const rowNode = this.params.node;

    // Keep track of original status if not already stored
    // if (!rowNode.data.originalStatus) {
    //   rowNode.data.originalStatus = rowNode.data.status;
    // }

    // Check if the new value is allowed based on business rules
    if (this.isValidStatusTransition(rowNode.data, selectedValue)) {
      // If valid, update the current value
      this.currentValue = selectedValue;

      // Set the modifiedByDropdown flag to true to indicate change via dropdown
      rowNode.data.modifiedByDropdown = true;

      // Use setDataValue to update the grid data without overriding other values
      rowNode.setDataValue(this.params.colDef.field, selectedValue);

      // Apply custom styles based on the new value
      this.applyStatusStyles(this.currentValue);

      // Refresh the grid cells to ensure the updated value is visible
      this.params.api.refreshCells({ rowNodes: [rowNode] });

      // Notify the service about the update if required
      this.contractService.notifyRowUpdated({
        rowId: rowNode.id,
        field: this.params.colDef.field,
        newValue: selectedValue,
      });

      // Optionally deselect all rows, then re-select the current row
      // this.params.api.deselectAll();
      rowNode.setSelected(true);
      this.params.api.refreshCells({ rowNodes: [rowNode], force: true });
    }
  }

  private updateRenderer(params: ICellRendererParams): void {
    const cellEditorParams = params.colDef?.cellEditorParams;

    if (typeof cellEditorParams === 'function') {
      const editorParams = cellEditorParams(params);

      this.options = this.getOptionsForStatus(editorParams.initialStatus);
      this.currentValue = params.data[this.statusFromColDef.colDef.id] || editorParams.initialStatus || '';
      this.isDisabled = this.shouldDisableDropdown(this.options, editorParams.initialStatus);
      this.applyStatusStyles(this.currentValue);
    } else {
      this.currentValue = params.data[this.statusFromColDef.colDef.id];
    }
  }

  private getOptionsForStatus(status: string): string[] {
    switch (status) {
      case 'Draft':
        return ['Draft'];
      case 'Published':
        return ['Draft', 'Published'];
      case 'Revoked':
        return ['Revoked'];
      default:
        return ['Draft', 'Published', 'Revoked'];
    }
  }

  private shouldDisableDropdown(options: string[], initialStatus: string): boolean {
    return (
      (options.length === 1 && (options[0] === 'Draft' || options[0] === 'Revoked')) ||
      initialStatus === 'Draft' ||
      initialStatus === 'Revoked'
    );
  }

  private isValidStatusTransition(rowData: any, newStatus: string): boolean {
    const currentStatus = rowData.status;

    const validTransitions: { [key: string]: string[] } = {
      Draft: ['Draft'],
      Published: ['Draft', 'Published'],
      Revoked: ['Revoked'],
    };

    return validTransitions[currentStatus]?.includes(newStatus) || false;
  }

  private applyStatusStyles(status: string): void {
    const rootStyles = getComputedStyle(document.documentElement);

    switch (status) {
      case 'Published':
        this.textColor = rootStyles.getPropertyValue('--success-color').trim();
        this.borderColor = rootStyles.getPropertyValue('--success-bg').trim();
        break;
      case 'Revoked':
        this.textColor = rootStyles.getPropertyValue('--danger-color').trim();
        this.borderColor = rootStyles.getPropertyValue('--danger-bg').trim();
        break;
      case 'Draft':
        this.textColor = rootStyles.getPropertyValue('--warning-color').trim();
        this.borderColor = rootStyles.getPropertyValue('--warning-bg').trim();
        break;
      default:
        this.textColor = 'inherit';
        this.borderColor = '#ccc';
        break;
    }
  }

  private handleBulkUpdate(bulkSelector: string): void {
    if (this.options.includes(bulkSelector)) {
      this.currentValue = bulkSelector;
      this.isDisabled = this.shouldDisableDropdown(this.options, bulkSelector);
      this.applyStatusStyles(this.currentValue);
    }
  }
}
