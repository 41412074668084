<div *ngIf="errorMessages.length > 0" class="extreme">
    <div class="row">
        <div class="col-12">
            <div class="border-box">
                <div class="d-flex align-items-center">
                    <p-message severity="error" icon="pi pi-times-circle"></p-message>
                    <h6>{{ 'MODULES.DISTRIBUTIONS.DISTRIBUTE.ERROR' | translate }}</h6>
                </div>
                <ul class="px-5">
                    <li *ngFor="let msg of errorMessages">{{ msg }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>