import { Component, OnInit } from '@angular/core';

import { ErrorBlockService } from '@app/shared/services/error-block.service';

@Component({
  selector: 'app-error-block',
  templateUrl: './error-block.component.html',
  styleUrls: ['./error-block.component.scss'],
})
export class ErrorBlockComponent implements OnInit {
  errorMessages: string[] = [];

  constructor(private errorBlockService: ErrorBlockService) {}

  ngOnInit(): void {
    this.errorBlockService.errorMessages$.subscribe(messages => {
      // Update the errorMessages array
      this.errorMessages = messages;
    });

    this.errorBlockService.clearErrors();
  }
}
