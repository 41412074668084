import { DatePipe } from '@angular/common';
import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { ValidationService } from '@app/core/services/validation.service';
import { ErrorBlockService } from '@app/shared/services/error-block.service';
import { CatalogService } from '@app/slm/services/catalog.service';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-add-catalog-metric',
  templateUrl: './add-catalog-metric.component.html',
  styleUrls: ['./add-catalog-metric.component.scss'],
})
export class AddCatalogMetricComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  createMetricForm!: FormGroup;

  offeringId!: string;

  conditionList: any = [];

  formatList: any = [
    {
      id: 1,
      name: 'Number',
      inactive: false,
    },
    {
      id: 5,
      name: 'N/A',
      inactive: false,
    },
    {
      id: 2,
      name: 'Time Interval',
      inactive: true,
    },
    {
      id: 3,
      name: 'Date',
      inactive: true,
    },
    {
      id: 4,
      name: 'Value Substitution',
      inactive: true,
    },
  ];

  subFormatList: any = [];

  periodList: any = [];

  connectorList: any = [];

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  isSelectedConnector = false;

  // errorMsgList: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private catalogService: CatalogService,
    private contractService: ContractService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private errorBlockService: ErrorBlockService
  ) {
    this.createMetricForm = this.formBuilder.group({
      id: ['', [Validators.maxLength(20)]],
      name: ['', [ValidationService.requiredValidator, Validators.maxLength(30)]],
      description: [null, [Validators.maxLength(1000)]],
      metricConnector: [''],
      offeringId: ['', [ValidationService.requiredValidator]],
      statement: ['', [ValidationService.requiredValidator, Validators.maxLength(250)]],
      condition: ['', [ValidationService.requiredValidator]],
      targetFormat: [''],
      subTargetFormat: ['', [ValidationService.requiredValidator]],
      period: ['', [ValidationService.requiredValidator]],
      versionName: [''],
      target: [],
      expected: [],
      startDate: [''],
      endDate: [''],
      versionStatement: [null, [Validators.maxLength(250)]],
    });

    this.createMetricForm.get('expected')?.disable();

    this.catalogService.selectedNodeData.subscribe((res: any) => {
      if (res) {
        this.createMetricForm.get('offeringId')?.patchValue(res.name);
        this.offeringId = res.id;
      }
    });
  }

  metricConnectorData(event: any): void {
    if (event.query.length >= 3) {
      const params = { name: event.query };

      this.catalogService.getMetricConnectors(params).subscribe((data: any) => {
        if (data) {
          this.connectorList = data;
        } else {
          this.connectorList = [];
        }
      });
    }
  }

  onSelectConnector(): void {
    this.isSelectedConnector = true;
  }

  onResetConnector(): void {
    this.isSelectedConnector = false;
  }

  updateSubFormat(): void {
    if (this.createMetricForm.get('targetFormat')?.value) {
      this.createMetricForm.get('subTargetFormat')?.reset();
      this.getSubFormatData();
    }
  }

  saveNewMetric(): void {
    this.errorBlockService.clearErrors();
    if (this.createMetricForm.invalid) {
      this.createMetricForm.markAllAsTouched();
    } else {
      const startDate = this.datePipe.transform(this.createMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.createMetricForm.get('endDate')?.value, 'yyyy-MM-dd');

      const payload = {
        metricNo: this.createMetricForm.get('id')?.value,
        name: this.createMetricForm.get('name')?.value,
        description: this.createMetricForm.get('description')?.value,
        metricConnectorId: this.createMetricForm.get('metricConnector')?.value?.id,
        businessOfferingId: this.offeringId,
        statement: this.createMetricForm.get('statement')?.value,
        conditionId: this.createMetricForm.get('condition')?.value,
        targetDataTypeId: this.createMetricForm.get('targetFormat')?.value,
        formatId: this.createMetricForm.get('subTargetFormat')?.value,
        periodId: this.createMetricForm.get('period')?.value,
        versionName: this.createMetricForm.get('versionName')?.value,
        target: this.createMetricForm.get('target')?.value,
        expected: this.createMetricForm.get('expected')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        versionStatement: this.createMetricForm.get('versionStatement')?.value,
      };

      this.catalogService.addMetricCatalog(payload).subscribe(
        (res: any) => {
          if (res) {
            this.catalogService.getCreatedMetricData({ res, isNew: true });
            const { versionName } = res.metricCatalogVersions[0];

            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CREATE_METRIC.SUCCESS', {
                metric: res.name,
                version: versionName,
              }),
            });
            this.resetForm();
            this.onResetConnector();
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            err.forEach((msg: any) => {
              this.errorBlockService.addError(msg.message);
            });
            // this.errorMsgList = errorList;
          }
        }
      );
    }
  }

  publishVersion(): void {
    this.errorBlockService.clearErrors();
    const startDate = this.datePipe.transform(this.createMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.createMetricForm.get('endDate')?.value, 'yyyy-MM-dd');

    const payload = {
      metricNo: this.createMetricForm.get('id')?.value,
      name: this.createMetricForm.get('name')?.value,
      description: this.createMetricForm.get('description')?.value,
      metricConnectorId: this.createMetricForm.get('metricConnector')?.value?.id,
      businessOfferingId: this.offeringId,
      statement: this.createMetricForm.get('statement')?.value,
      conditionId: this.createMetricForm.get('condition')?.value,
      targetDataTypeId: this.createMetricForm.get('targetFormat')?.value,
      formatId: this.createMetricForm.get('subTargetFormat')?.value,
      periodId: this.createMetricForm.get('period')?.value,
      versionName: this.createMetricForm.get('versionName')?.value,
      target: this.createMetricForm.get('target')?.value,
      expected: this.createMetricForm.get('expected')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      versionStatement: this.createMetricForm.get('versionStatement')?.value,
    };

    this.catalogService.publishMetricVersion(payload).subscribe(
      (res: any) => {
        if (res) {
          this.catalogService.getCreatedMetricData({ res, isNew: true });
          const { versionName } = res.metricCatalogVersions[0];

          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CREATE_METRIC.METRIC_PUBLISH', {
              metric: res.name,
              version: versionName,
            }),
          });
          this.resetForm();
          this.onResetConnector();
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          err.forEach((msg: any) => {
            this.errorBlockService.addError(msg.message);
          });
          // this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmPublish(event: Event): void {
    this.errorBlockService.clearErrors();

    if (!this.createMetricForm.get('startDate')?.value || !this.createMetricForm.get('endDate')?.value) {
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      this.errorBlockService.addError(msg.message);
      this.createMetricForm.markAllAsTouched();
    } else if (this.createMetricForm.invalid) {
      this.createMetricForm.markAllAsTouched();
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion();
        },
      });
    }
  }

  resetForm(): void {
    this.createMetricForm.reset();
    this.updateSubFormat();
    this.minEndDate = new Date();
    this.errorBlockService.clearErrors();
    this.closeClicked.emit(false);
  }

  getConditionData(): void {
    this.catalogService.getMetricCondition().subscribe((res: any) => {
      this.conditionList = res;
    });
  }

  //   getFormatData(): void {
  //     this.catalogService.getTargetFormat().subscribe((res: any) => {
  //       this.formatList = res;
  //     });
  //   }

  // sub format list will be filtered based on format value
  getSubFormatData(): void {
    this.catalogService.getMetricSubFormat().subscribe((res: any) => {
      this.subFormatList = [];
      const subFormatRes = res;
      const formatValue = this.createMetricForm.get('targetFormat')?.value;

      if (formatValue) {
        const filteredData = subFormatRes.filter((item: any) => item.metricTargetDataTypeId === formatValue);

        this.subFormatList = filteredData;
      }
    });
  }

  metricPeriodData(): void {
    this.catalogService.getMetricPeriod().subscribe((res: any) => {
      if (res) {
        this.periodList = res;
      }
    });
  }

  onSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.createMetricForm.get('endDate')?.reset();
  }

  getTagetValue(): void {
    if (this.createMetricForm.get('target')?.value) {
      this.createMetricForm.get('expected')?.enable();
    } else {
      this.createMetricForm.get('expected')?.disable();
      this.createMetricForm.patchValue({ expected: null });
    }
  }

  ngOnInit(): void {
    this.getConditionData();
    // this.getFormatData();
    this.metricPeriodData();
  }
}
