import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private excludedRoutes: Set<string> = new Set();

  private excludedContexts: Set<string> = new Set(); // Add context exclusion support

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  setLoading(loading: boolean): void {
    this.loadingSubject.next(loading);
  }

  getLoading(): Observable<boolean> {
    return this.loading$;
  }

  setExcludedRoutes(routes: string[]): void {
    this.excludedRoutes = new Set(routes);
  }

  isRouteExcluded(route: string): boolean {
    return this.excludedRoutes.has(route);
  }

  // New method: Manage context exclusions
  setExcludedContexts(contexts: string[]): void {
    this.excludedContexts = new Set(contexts);
  }

  isContextExcluded(context: string): boolean {
    return this.excludedContexts.has(context);
  }
}
