import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, filter } from 'rxjs';

import { environment } from '@env/environment';

const routes = {
  globalSearch: `${environment.SLMUrl}/globalsearch`,
};

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  filterSubject = new BehaviorSubject<any[]>([]); // Subject to store filters

  public filters$ = this.filterSubject.asObservable(); // Expose observable to subscribe to

  private selectedItemSource = new BehaviorSubject<any>(null); // Initialize with null or empty value

  selectedItem$ = this.selectedItemSource.asObservable().pipe(
    filter(item => !!item?.hierarchyAttributes) // Emit only valid items
  );

  constructor(private httpClient: HttpClient) {}

  /**
   * Perform a global search with the provided request payload.
   * @param request - The search request payload.
   * @returns Observable containing search results.
   */
  getGlobalSeach(request: any): any {
    return this.httpClient.post(`${routes.globalSearch}`, request);
  }

  /**
   * Update the current set of filters.
   * @param filterConditions - An array of filter conditions to be applied.
   */
  setFilters(filterConditions: any[]): void {
    this.filterSubject.next(filterConditions); // Update filter conditions
  }

  /**
   * Clear all filters by resetting the filter subject.
   */

  clearFilters(): void {
    this.filterSubject.next([]); // Clear filters
  }

  /**
   * Update the selected item with the provided value.
   * @param item - The item to set as selected.
   */
  setSelectedItem(item: any): void {
    this.selectedItemSource.next(item); // Update the selected item
  }
}
