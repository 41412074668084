import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'app-custom-date',
  template: `
    <p-calendar
      [(ngModel)]="date"
      appendTo="body"
      [showIcon]="true"
      [monthNavigator]="true"
      [showButtonBar]="false"
      [readonlyInput]="true"
      (onSelect)="onDateSelect($event)"
      class="custom-select"
      dateFormat="dd-mm-yy"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [disabled]="isRevoked"
    ></p-calendar>
  `,
  styles: [
    `
      .custom-select {
        width: 100%;
        font-size: 14px;
        background: transparent;
      }
      .custom-select:focus {
        outline: none;
        border: none;
      }
      ::ng-deep .custom-select > .p-calendar {
        height: 25px;
        outline: none;
        border: none;
      }
      ::ng-deep .custom-select > .p-calendar:focus {
        outline: none;
        border: none;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, CalendarModule, FormsModule],
})
export class DateRendererComponent implements ICellRendererAngularComp {
  date!: Date;

  params: any;

  // Initial constraints
  initialMinDate = new Date('1901-01-01');

  initialMaxDate = new Date('9999-12-31');

  minDate: Date = this.initialMinDate;

  maxDate: Date = this.initialMaxDate;

  selectedStartDate!: Date;

  selectedEndDate!: Date;

  isRevoked = false;

  tableColumns: any; // Add interface later

  statusFromColDef: any;

  startDateFromColDef: any;

  endDateFromColDef: any;

  constructor(private datePipe: DatePipe) {}

  agInit(params: any): void {
    this.params = params;
    this.tableColumns = params.columnApi.getColumns();
    this.statusFromColDef = this.tableColumns.find(
      (col: any) => col.colDef.headerName === 'Status' // Or use another unique property like colDef.field === '371'
    );
    this.startDateFromColDef = this.tableColumns.find(
      (col: any) => col.colDef.headerName === 'Start Date' // Or use another unique property like colDef.field === '371'
    );
    this.endDateFromColDef = this.tableColumns.find(
      (col: any) => col.colDef.headerName === 'End Date' // Or use another unique property like colDef.field === '371'
    );
    this.selectedStartDate = params.data[this.startDateFromColDef.colDef.id];
    this.selectedEndDate = params.data[this.endDateFromColDef.colDef.id];
    this.isRevoked = params.data[this.statusFromColDef.colDef.id] === 'Revoked';
    this.updateRenderer(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateRenderer(params);

    return true;
  }

  updateRenderer(params: ICellRendererParams): void {
    const field = params.colDef?.field;

    // Update min and max dates based on field and existing data
    if (field === String(this.startDateFromColDef.colDef.id)) {
      this.minDate = params.data[this.startDateFromColDef.colDef.id]
        ? new Date(this.selectedStartDate)
        : this.initialMinDate;
      this.maxDate = params.data[this.endDateFromColDef.colDef.id]
        ? new Date(params.data.enddate)
        : this.initialMaxDate;
    } else if (field === String(this.endDateFromColDef.colDef.id)) {
      // end date min range will be greater than startDate (startDate + 1)
      const startDate = new Date(params.data[this.startDateFromColDef.colDef.id]);
      const minEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);

      this.minDate = params.data[this.startDateFromColDef.colDef.id] ? new Date(minEndDate) : this.initialMinDate;
      this.maxDate = params.data[this.endDateFromColDef.colDef.id]
        ? new Date(this.selectedEndDate)
        : this.initialMaxDate;
    }

    // Ensure date is in the correct format
    const rawDate = params.value
      ? this.datePipe.transform(params.value, 'yyyy-MM-dd')
      : this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.date = rawDate ? new Date(rawDate) : new Date();

    if (Number.isNaN(this.date.getTime())) {
      this.date = new Date(); // Default to current date if invalid
    }
  }

  onDateSelect(date: Date): void {
    const { field } = this.params.colDef;

    if (field === String(this.startDateFromColDef.colDef.id)) {
      this.params.data[field] = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.minDate = date; // Ensure end date cannot be before the new start date
      if (
        this.params.data[this.endDateFromColDef.colDef.id] &&
        new Date(this.params.data[this.endDateFromColDef.colDef.id]) < this.minDate
      ) {
        this.params.data[this.endDateFromColDef.colDef.id] = this.datePipe.transform(this.minDate, 'yyyy-MM-dd');
        this.maxDate = this.minDate; // Update maxDate for end date picker
      }
    } else if (field === String(this.endDateFromColDef.colDef.id)) {
      if (date >= this.minDate) {
        this.params.data[field] = this.datePipe.transform(date, 'yyyy-MM-dd');
        this.maxDate = date; // Update maxDate to the selected end date
      } else {
        // If the end date is before the start date, revert to the previous end date
        this.date = new Date(this.params.data[field]);

        return;
      }
    }

    // Force the grid to refresh the cell to reflect the new date
    this.params.api.refreshCells({ rowNodes: [this.params.node], force: true });

    // Programmatically select the row and trigger the row selection event
    const { node } = this.params;

    if (node) {
      node.setSelected(true); // This triggers row selection
      this.params.api.dispatchEvent({
        type: 'rowSelected',
        node,
        api: this.params.api,
      });
    }
  }
}
