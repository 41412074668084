import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MenuItem } from 'primeng/api';

import { ContractPermissionService } from '@app/slm/services/contract-permission.service';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent implements ICellRendererAngularComp {
  contractData: any;

  actionMenuItems: MenuItem[] = [];

  constructor(
    private contractPermission: ContractPermissionService,
    private contractService: ContractService,
    private translateService: TranslateService
  ) {
    this.getActionsMenu();
  }

  agInit(params: ICellRendererParams): void {
    const contractColumns: any = params.columnApi.getColumns();

    // Find  the column dynamically based on a unique property (e.g., headerName or field)
    const targetColumn = contractColumns.find(
      (col: any) => col.colDef.headerName === 'Contract ID' // Or use another unique property like colDef.field === '371'
    );

    // Check if the column exists and has an ID attribute
    if (targetColumn && targetColumn.colDef.id) {
      // Pass the dynamically retrieved ID to the service
      this.contractData = params.data[targetColumn.colDef.id];
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.contractData = params;

    return true;
  }

  getViewContractByID(data?: boolean): void {
    this.contractService.toogleViewSidebar(data || false);
    this.contractService.getViewData(this.contractData);
  }

  getActionsMenu(): void {
    this.actionMenuItems = [
      {
        label: this.translateService.instant('MODULES.CONTRACTS.ADD_VERSION'),
        icon: 'pi pi-plus-circle',
        command: () => {
          this.contractService.toogleAddSidebar(true);

          this.contractService.getViewData(this.contractData);
        },
        disabled: !this.contractPermission.hasAddContractVersion(),
      },
      {
        label: this.translateService.instant('MODULES.CONTRACTS.EDIT_VERSION'),
        icon: 'pi pi-file-edit',
        command: () => {
          this.contractService.toogleSidebar(true);
          this.contractService.getViewData(this.contractData);
        },
        disabled: !this.contractPermission.hasEditContractVersion(),
      },
    ];
  }
}
