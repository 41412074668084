import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { CredentialsService } from '@app/core/services/credentials.service';
import { ValidationService } from '@app/core/services/validation.service';
import { HeadersService } from '@app/report/services/headers.service';
import { ErrorBlockService } from '@app/shared/services/error-block.service'; // Import the error block service

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  newPasswordform!: FormGroup;

  isValidUpperCase = false;

  isValidLowerCase = false;

  isValidNumberCase = false;

  isValidSpecialCharCase = false;

  minCurrectLength = 0;

  isError = false;

  get disableResetBtn(): boolean {
    return this.newPasswordform.invalid || this.minCurrectLength < 3;
  }

  constructor(
    private formBuilder: FormBuilder,
    public credentialsService: CredentialsService,
    private router: Router,
    private headerService: HeadersService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private errorBlockService: ErrorBlockService // Inject the error block service
  ) {}

  ngOnInit(): void {
    this.initForms();
    this.formChangeDetection();
  }

  resetPassword(): void {
    if (this.newPasswordform.valid) {
      if (this.credentialsService.userId) {
        this.headerService
          .updatePassword(
            {
              currentPassword: this.newPasswordform.get('currentPassword')?.value,
              newPassword: this.newPasswordform.get('newPassword')?.value,
            },
            this.credentialsService.userId
          )
          .subscribe(
            () => {
              this.messageService.add({
                severity: 'success',
                summary: this.translateService.instant('CHANGE_PASSWORD_DIALOG.RESET_SUCCESS'),
              });
              this.closeClicked.emit(true);
              this.logout();
            },
            (err: any) => {
              // Clear previous errors before adding new ones
              this.errorBlockService.clearErrors();

              // Add new errors to the service
              if (err && Array.isArray(err)) {
                err.forEach((msg: any) => {
                  this.errorBlockService.addError(msg.message); // Add each error message to the service
                });
              } else {
                this.errorBlockService.addError('An unknown error occurred'); // Default error message
              }
            }
          );
      }
    }
  }

  logout(): void {
    this.credentialsService.setCredentials();
    this.router.navigate(['/auth/login']);
  }

  cancel(): void {
    this.newPasswordform.reset();
    this.closeClicked.emit(true);
  }

  private initForms(): void {
    this.newPasswordform = this.formBuilder.group(
      {
        currentPassword: ['', [ValidationService.requiredValidator]],
        newPassword: ['', [ValidationService.requiredValidator, Validators.minLength(8), Validators.maxLength(64)]],
        confirmPassword: ['', [ValidationService.requiredValidator]],
      },
      {
        validator: this.ConfirmedValidator('newPassword', 'confirmPassword'),
      }
    );

    this.newPasswordform.get('newPassword')?.valueChanges.subscribe(val => {
      this.minCurrectLength = 0;

      this.isValidUpperCase = val.match(/[A-Z]/g);
      this.isValidLowerCase = val.match(/[a-z]/g);
      this.isValidNumberCase = val.match(/[0-9]/g);
      this.isValidSpecialCharCase = val.match(/[$&+,:;=?@#|'<>.^*()%!-]/g);
      if (this.isValidUpperCase) {
        this.minCurrectLength += 1;
      }
      if (this.isValidLowerCase) {
        this.minCurrectLength += 1;
      }
      if (this.isValidNumberCase) {
        this.minCurrectLength += 1;
      }
      if (this.isValidSpecialCharCase) {
        this.minCurrectLength += 1;
      }
    });
  }

  private ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  private formChangeDetection(): void {
    this.newPasswordform.valueChanges.subscribe(() => {
      this.errorBlockService.clearErrors(); // Clear errors whenever form value changes
    });
  }
}
