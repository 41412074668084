<ng-container  *ngIf="loading">
    <div class="row mt-2">
        <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
            <p-skeleton></p-skeleton>
        </div>
    </div>
</ng-container>
<ng-container  *ngIf="!loading">
    <form [formGroup]="dynamicForm" *ngIf="formControlsInitialized">
        <div class="row mt-2">
            <ng-container *ngFor="let udfData of udfInfo">
                <!-- Text Input -->
                <ng-container *ngIf="
            udfData.objectFieldType === 'String' ||
            udfData.objectFieldType === 'Text'
          ">
                    <div class="col-4">
                        <label class="fw-bold mb-2" [ngClass]="{ required: udfData.isMandatory }" *ngIf="udfData.name">{{
                            udfData.name
                            }}</label>
                        <span class="w-100 my-2" *ngIf="udfData.fieldName">
                            <input class="w-100  p-inputtext-sm" placeholder="{{ udfData.name }}" type="text"
                                pInputText [formControlName]="udfData.fieldName" />
                        </span>
                        <div *ngIf="dynamicForm.get(udfData.fieldName)?.touched">
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['required']" class="text-danger">
                                {{ 'VALIDATION.REQUIRED' | translate }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['maxlength']"
                                class="text-danger">
                                {{
                                'VALIDATION.MAXLENGTH_CF_TEXT'
                                | translate : { maxValue: udfData.maximumValue }
                                }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['minlength']"
                                class="text-danger">
                                {{
                                'VALIDATION.MINLENGTH_CF_TEXT'
                                | translate : { minValue: udfData.minimumValue }
                                }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['pattern']" class="text-danger">
                                {{ 'VALIDATION.INVALID_STRING_PATTERN' | translate }}
                            </small>
                        </div>
                    </div>
                </ng-container>

                <!-- Number Input -->
                <ng-container *ngIf="udfData.objectFieldType === 'Number' && udfData.fieldName">
                    <div class="col-4">
                        <label class="fw-bold mb-2" for="number-input" [ngClass]="{ required: udfData.isMandatory }" *ngIf="udfData.name">{{ udfData.name }}</label>
                        <span class="w-100 my-2" *ngIf="udfData.name">
                            <p-inputNumber mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="4"
                                placeholder="{{ udfData.name }}" [formControlName]="udfData.fieldName"></p-inputNumber>
                        </span>
                        <div *ngIf="dynamicForm.get(udfData.fieldName)?.touched">
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['required']" class="text-danger">
                                {{ 'VALIDATION.REQUIRED' | translate }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['min']" class="text-danger">
                                {{
                                'VALIDATION.MINLENGTH_CF_NUMBER'
                                | translate : { minValue: udfData.minimumValue }
                                }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['max']" class="text-danger">
                                {{
                                'VALIDATION.MAXLENGTH_CF_NUMBER'
                                | translate : { maxValue: udfData.maximumValue }
                                }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['pattern']" class="text-danger">
                                {{ 'VALIDATION.INVALID_PATTERN' | translate }}
                            </small>
                        </div>
                    </div>
                </ng-container>

                <!-- Dropdown -->
                <ng-container *ngIf="udfData.objectFieldType === 'Value Set'">
                    <div class="col-4">
                        
                        <label class="fw-bold mb-2" for="title" [ngClass]="{ required: udfData.isMandatory }" *ngIf="udfData.name">{{
                            udfData.name
                            }}
                        </label>
                        <span class="w-100 my-2">
                            <p-dropdown styleClass="w-100 " [placeholder]="udfData.name"
                                [autoDisplayFirst]="false" [options]="valueSetMap[udfData.fieldName]"
                                [formControlName]="udfData.fieldName" optionLabel="title" optionValue="key">
                            </p-dropdown>
                        </span>
                        <div *ngIf="dynamicForm.get(udfData.fieldName)?.touched">
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['required']" class="text-danger">
                                {{ 'VALIDATION.REQUIRED' | translate }}
                            </small>
                        </div>
                    </div>
                </ng-container>

              

                <ng-container *ngIf="['Date and Time', 'Date', 'Month', 'Year'].includes(udfData.objectFieldType)">
                    <div class="col-4">
                        <label class="fw-bold mb-2" *ngIf="udfData.name" [ngClass]="{ required: udfData.isMandatory }">
                            {{ udfData.name }}
                        </label>
                        <span class="w-100 my-2 " *ngIf="udfData.fieldName">
                            <p-calendar styleClass="w-100 " placeholder="{{ udfData.name }}"
                                [formControlName]="udfData.fieldName" [showIcon]="true" [readonlyInput]="true"
                                [view]="getViewType(udfData.objectFieldType)"
                                [dateFormat]="getDateFormat(udfData.objectFieldType)"
                                [showTime]="udfData.objectFieldType === 'Date and Time'" [showSeconds]="true"
                                [minDate]="udfData.minDate" [maxDate]="udfData.maxDate" appendTo="body">
                            </p-calendar>
                        </span>
                        <div *ngIf="dynamicForm.get(udfData.fieldName)?.touched">
                            <!-- Required Field Validation -->
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['required']" class="text-danger">
                                {{ 'VALIDATION.REQUIRED' | translate }}
                            </small>

                            <!-- Invalid Date Range Error -->
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['invalidDateRange']"
                                class="text-danger">
                                {{ 'VALIDATION.INVALID_DATE_RANGE' | translate }}
                            </small>

                            <!-- Future Date Only Error -->
                            <small
                                *ngIf="!dynamicForm.get(udfData.fieldName)?.errors?.['invalidDateRange'] && dynamicForm.get(udfData.fieldName)?.errors?.['futureDate']"
                                class="text-danger">
                                {{ getFutureDateValidation(udfData.fieldName) }}
                            </small>

                            <!-- Past Date Only Error -->
                            <small
                                *ngIf="!dynamicForm.get(udfData.fieldName)?.errors?.['invalidDateRange'] && dynamicForm.get(udfData.fieldName)?.errors?.['pastDate']"
                                class="text-danger">

                                {{ getPastDateValidation(udfData.fieldName) }}
                            </small>
                        </div>
                    </div>
                </ng-container>


                <!-- Textarea -->
                <ng-container *ngIf="udfData.objectFieldType === 'Rich Text'">
                    <div class="col-4">
                        <label class="fw-bold mb-2" [ngClass]="{ required: udfData.isMandatory }" *ngIf="udfData.name">{{
                            udfData.name
                            }}</label>
                        <span class="w-100 my-2" *ngIf="udfData.fieldName">
                            <textarea class="w-100" placeholder="{{ udfData.name }}" pInputTextarea
                                [formControlName]="udfData.fieldName"></textarea>
                        </span>
                        <div *ngIf="dynamicForm.get(udfData.fieldName)?.touched">
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['maxlength']"
                                class="text-danger">
                                {{
                                'VALIDATION.MAXLENGTH_CF_TEXT'
                                | translate : { maxValue: udfData.maximumValue }
                                }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['minlength']"
                                class="text-danger">
                                {{
                                'VALIDATION.MINLENGTH_CF_TEXT'
                                | translate : { minValue: udfData.minimumValue }
                                }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['pattern']" class="text-danger">
                                {{ 'VALIDATION.INVALID_PATTERN' | translate }}
                            </small>
                            <small *ngIf="dynamicForm.get(udfData.fieldName)?.errors?.['required']" class="text-danger">
                                {{ 'VALIDATION.REQUIRED' | translate }}
                            </small>
                        </div>
                    </div>
                </ng-container>

                <!-- Will be added later (Upload) -->
                <ng-container *ngIf="udfData.objectFieldType === 'upload'">
                    <div class="col-4">
                        <span class="w-100 my-1" *ngIf="udfData.fieldName">
                            <p-fileUpload name="file[]" mode="advanced" [showUploadButton]="false"
                                [showCancelButton]="false" (onSelect)="uploadReportKPI($event)"
                                [uploadLabel]="getCustomChooseLabel()" [chooseLabel]="
                  'MODULES.DISTRIBUTIONS.VIEW_DISTRIBUTIONS.UPLOAD' | translate
                " [invalidFileSizeMessageSummary]="
                  'File size exceeds the maximum allowed size.'
                " [invalidFileTypeMessageSummary]="
                  getInvalidFileTypeMessageSummary()
                " [invalidFileTypeMessageDetail]="
                  getInvalidFileTypeMessageDetail()
                " [customUpload]="true" accept=".csv, .xlsx , .xls " [maxFileSize]="104857600"
                                (onRemove)="clearUploadValue($event)">
                                <ng-template pTemplate="content">
                                    <div class="custom-file-upload">
                                        <span class="fnt-size"> {{ getCustomChooseLabel() }} </span>
                                    </div>
                                </ng-template>
                            </p-fileUpload>
                        </span>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <button type="submit" style="display: none"></button>
    </form>
</ng-container>