import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';

import { ValidationService } from '@app/core/services/validation.service';
import { ErrorBlockService } from '@app/shared/services/error-block.service';
import { ContractPermissionService } from '@app/slm/services/contract-permission.service';
import { ContractService } from '@app/slm/services/contract.service';
import { OrganizationService } from '@app/slm/services/organization.service';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss'],
})
export class AddContractComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  contractForm!: FormGroup;

  organizationData: any = [];

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  // errorMsgList: any = [];

  selectedMetrics: any = [];

  isPopupVisible = false;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private organizationService: OrganizationService,
    private contractService: ContractService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public contractPermission: ContractPermissionService,
    private errorBlockService: ErrorBlockService
  ) {
    this.contractForm = this.formBuilder.group({
      id: ['', [Validators.maxLength(20)]],
      name: ['', [ValidationService.requiredValidator, Validators.maxLength(30)]],
      description: [null, [Validators.maxLength(1000)]],
      customer: ['', [ValidationService.requiredValidator]],
      provider: ['', [ValidationService.requiredValidator]],
      versionName: ['', [Validators.maxLength(20)]],
      startDate: [''],
      endDate: [''],
      statement: [null, [Validators.maxLength(250)]],
    });
  }

  ngOnInit(): void {
    this.getOrganizationDetails();
    // getting selected metric list
    this.contractService.selectedMetricList.subscribe((res: any) => {
      if (res) {
        this.selectedMetrics = res;
      }
    });
  }

  getOrganizationDetails(): void {
    this.organizationService.getMetricTreeData().subscribe((res: any) => {
      this.organizationData = res?.children;
      this.organizationData.forEach((node: TreeNode<any>) => {
        this.expandRecursive(node, true);
        this.disableRecursive(node);
      });
    });
  }

  // customer and provider same value validation
  nodeSelect(): void {
    const customer = this.contractForm.get('customer')?.value?.businessId;
    const provider = this.contractForm.get('provider')?.value?.businessId;

    // Clear existing errors on the provider control
    this.contractForm.controls['provider'].setErrors(null);
    // Set custom invalid error if customer and provider are the same
    if (customer && provider && customer === provider) {
      this.contractForm.controls['provider'].setErrors({ invalid: true });
    }
    // Check if provider is required
    if (customer && !provider) {
      this.contractForm.controls['provider'].setErrors({ required: true });
    }
    // Optionally, mark the provider control as touched
    this.contractForm.controls['provider'].markAsTouched();
  }

  onSelectStartDate(date: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    this.contractForm.get('endDate')?.reset();
  }

  saveContract(): void {
    this.errorBlockService.clearErrors();
    if (this.contractForm.invalid) {
      this.contractForm.markAllAsTouched();
    } else {
      const startDate = this.datePipe.transform(this.contractForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.contractForm.get('endDate')?.value, 'yyyy-MM-dd');

      const payload = {
        contractNo: this.contractForm.get('id')?.value,
        name: this.contractForm.get('name')?.value,
        description: this.contractForm.get('description')?.value,
        customerId: this.contractForm.get('customer')?.value?.businessId,
        providerId: this.contractForm.get('provider')?.value?.businessId,
        versionName: this.contractForm.get('versionName')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        statement: this.contractForm.get('statement')?.value,
        metricCatalogIds: this.selectedMetrics?.metricCatalogIds,
        providerEntity: this.selectedMetrics?.providerEntity,
      };

      this.contractService.addContract(payload).subscribe(
        (res: any) => {
          if (res) {
            this.contractService.getCreatedContract({ res, isNew: true });
            const versionName = res.contractVersionList[0].version;

            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CONTRACTS.SUCCESS', {
                contract: res.name,
                version: versionName,
              }),
            });
            this.closeCreateContract();
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            err.forEach((msg: any) => {
              this.errorBlockService.addError(msg.message);
            });
            // this.errorMsgList = errorList;
          }
        }
      );
    }
  }

  publishVersion(): void {
    const startDate = this.datePipe.transform(this.contractForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.contractForm.get('endDate')?.value, 'yyyy-MM-dd');

    const payload = {
      contractNo: this.contractForm.get('id')?.value,
      name: this.contractForm.get('name')?.value,
      description: this.contractForm.get('description')?.value,
      customerId: this.contractForm.get('customer')?.value?.businessId,
      providerId: this.contractForm.get('provider')?.value?.businessId,
      versionName: this.contractForm.get('versionName')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      statement: this.contractForm.get('statement')?.value,
      metricCatalogIds: this.selectedMetrics?.metricCatalogIds,
      providerEntity: this.selectedMetrics?.providerEntity,
    };

    this.contractService.publishContract(payload).subscribe(
      (res: any) => {
        if (res) {
          this.contractService.getCreatedContract(res);
          const versionName = res.contractVersionList[0].version;

          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CONTRACTS.CONTRACT_PUBLISH', {
              contract: res.name,
              version: versionName,
            }),
          });
          this.closeCreateContract();
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          err.forEach((msg: any) => {
            this.errorBlockService.addError(msg.message);
          });
          // this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmPublish(event: Event): void {
    this.errorBlockService.clearErrors();

    if (!this.contractForm.get('startDate')?.value || !this.contractForm.get('endDate')?.value) {
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      this.errorBlockService.addError(msg.message);
      this.contractForm.markAllAsTouched();
    } else if (this.contractForm.invalid) {
      this.contractForm.markAllAsTouched();
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion();
        },
      });
    }
  }

  closeCreateContract(): void {
    this.contractForm.reset();
    this.minEndDate = new Date();
    this.errorBlockService.clearErrors();
    this.organizationData = [];
    this.selectedMetrics = [];
    this.closeClicked.emit(false);
  }

  private expandRecursive(node: TreeNode, isExpand: boolean): void {
    const nodeExpand = node;

    nodeExpand.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
    // to check last node
    if (node.children && node.children.length === 0) {
      nodeExpand.expanded = !nodeExpand.expanded;
    }
  }

  private disableRecursive(node: any): void {
    if (node.countOfChildren > 0) {
      const nodeSelected = node;

      nodeSelected.selectable = false;
      node.children.forEach((childNode: any) => {
        this.disableRecursive(childNode);
      });
    }
  }
}
