import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { ErrorBlockService } from '@app/shared/services/error-block.service';
import { ContractPermissionService } from '@app/slm/services/contract-permission.service';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-add-contract-version',
  templateUrl: './add-contract-version.component.html',
  styleUrls: ['./add-contract-version.component.scss'],
})
export class AddContractVersionComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  contractSubscription!: Subscription;

  viewData: any = [];

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  createVersionForm!: FormGroup;

  errorMsgList: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private contractService: ContractService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public contractPermission: ContractPermissionService,
    private errorBlockService: ErrorBlockService
  ) {}

  ngOnInit(): void {
    this.createVersionForm = this.formBuilder.group({
      versionName: ['', [Validators.maxLength(20)]],
      startDate: [''],
      endDate: [''],
      statement: [null, [Validators.maxLength(250)]],
    });

    this.contractSubscription = this.contractService.viewData.subscribe((res: any) => {
      if (res) {
        this.viewData = [];
        this.getViewContract(res);
      }
    });
  }

  getViewContract(contractData: any): void {
    this.contractService.getContractByID(contractData).subscribe((res: any) => {
      if (res) {
        this.viewData = res;

        Object.keys(this.viewData).forEach(key => {
          this.viewData[key] = this.viewData[key] ? this.viewData[key] : 'N/A';
        });
      }
    });
  }

  saveNewVersion(id: number): void {
    this.errorBlockService.clearErrors();
    if (this.createVersionForm.invalid) {
      this.createVersionForm.markAllAsTouched();
    } else {
      const startDate = this.datePipe.transform(this.createVersionForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.createVersionForm.get('endDate')?.value, 'yyyy-MM-dd');

      const payload = {
        version: this.createVersionForm.get('versionName')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        statement: this.createVersionForm.get('statement')?.value,
      };

      this.contractService.addVersion(id, payload).subscribe(
        (res: any) => {
          if (res) {
            this.contractService.getCreatedContract(res);
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CONTRACTS.VERSION_SUCCESS', {
                contract: this.viewData?.contractName,
                version: res.version,
              }),
            });
            this.resetForm();
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            err.forEach((msg: any) => {
              // errorList.push(msg.message);
              this.errorBlockService.addError(msg.message);
            });
            // this.errorMsgList = errorList;
          }
        }
      );
    }
  }

  publishVersion(id: number): void {
    const startDate = this.datePipe.transform(this.createVersionForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.createVersionForm.get('endDate')?.value, 'yyyy-MM-dd');

    const payload = {
      version: this.createVersionForm.get('versionName')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      statement: this.createVersionForm.get('statement')?.value,
    };

    this.contractService.publishVersion(id, payload).subscribe(
      (res: any) => {
        if (res) {
          this.contractService.getCreatedContract(res);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CONTRACTS.VERSION_PUBLISH', {
              contract: this.viewData?.contractName,
              version: res.version,
            }),
          });
          this.resetForm();
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          err.forEach((msg: any) => {
            // errorList.push(msg.message);
            this.errorBlockService.addError(msg.message);
          });
          // this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmPublish(event: Event): void {
    this.errorBlockService.clearErrors();

    if (!this.createVersionForm.get('startDate')?.value || !this.createVersionForm.get('endDate')?.value) {
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      this.errorBlockService.addError(msg.message);
      this.createVersionForm.markAllAsTouched();
    } else if (this.createVersionForm.invalid) {
      this.createVersionForm.markAllAsTouched();
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion(this.viewData.id);
        },
      });
    }
  }

  resetForm(): void {
    this.createVersionForm.reset();
    this.minEndDate = new Date();
    this.viewData = [];
    this.errorMsgList = [];
    this.closeClicked.emit(false);
    this.errorBlockService.clearErrors();
  }

  onSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.createVersionForm.get('endDate')?.reset();
  }

  ngOnDestroy(): void {
    this.contractSubscription.unsubscribe();
  }
}
