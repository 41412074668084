import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

import { ErrorBlockService } from '@app/shared/services/error-block.service';
import { ContractPermissionService } from '@app/slm/services/contract-permission.service';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-edit-contract-version',
  templateUrl: './edit-contract-version.component.html',
  styleUrls: ['./edit-contract-version.component.scss'],
})
export class EditContractVersionComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild('op', { static: false }) overlayPanel: any;

  @ViewChild('paginationReset') paginationReset!: Table;

  contractSubscription!: Subscription;

  editVersionForm!: FormGroup;

  viewData: any = [];

  viewVersionList: any = [];

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  errorMsgList: any = [];

  selectedVersion: any;

  filteredVersion: any = [];

  statusList: any = [
    { label: 'Draft', class: 'warning-chip' },
    { label: 'Published', class: 'success-chip' },
    { label: 'Revoked', class: 'danger-chip' },
  ];

  selectedStatusFilter: any = { Draft: false, Published: false, Revoked: false };

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private contractService: ContractService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public contractPermission: ContractPermissionService,
    private errorBlockService: ErrorBlockService
  ) {}

  ngOnInit(): void {
    this.editVersionForm = this.formBuilder.group({
      versionName: ['', [Validators.maxLength(20)]],
      startDate: [''],
      endDate: [''],
      statement: [null, [Validators.maxLength(250)]],
      statusFilter: new FormArray([]),
    });

    this.addCheckboxes();

    this.contractSubscription = this.contractService.viewData.subscribe((res: any) => {
      if (res) {
        this.viewData = [];
        this.viewVersionList = [];
        this.getViewContract(res);
      }
    });
  }

  getViewContract(contractData: any): void {
    this.contractService.getContractByID(contractData).subscribe((res: any) => {
      if (res) {
        this.viewData = res;

        this.viewVersionList = this.viewData.contractVersionList;
        this.patchValue(this.viewVersionList[0]);

        [this.selectedVersion] = this.viewVersionList;

        this.filteredVersion = this.viewVersionList;

        Object.keys(this.viewData).forEach(key => {
          this.viewData[key] = this.viewData[key] ? this.viewData[key] : 'N/A';
        });
      }
    });
  }

  patchValue(data: any): void {
    this.onSelectStartDate(new Date(data.startDate));
    this.selectedVersion = data;
    this.editVersionForm.patchValue({
      versionName: data.version,
      startDate: data.startDate ? new Date(data.startDate) : '',
      endDate: data.endDate ? new Date(data.endDate) : '',
      statement: data.statement ? data.statement : '',
    });

    if (data.status !== 'Draft') {
      this.editVersionForm.disable();
      if (data.status === 'Published') {
        this.editVersionForm.get('statement')?.enable();
      }
    } else {
      this.editVersionForm.enable();
    }
    this.editVersionForm.get('statusFilter')?.enable();
  }

  applyStatusFilter(event: any, field: string): void {
    Object.keys(this.selectedStatusFilter).forEach(key => {
      if (key === field) {
        this.selectedStatusFilter[key] = event.checked;
      }
    });

    // filter the versions from the list
    const filterList: any = [];

    this.paginationReset?.reset();

    this.viewVersionList.forEach((status: any) => {
      if (this.selectedStatusFilter[status.status]) {
        filterList.push(status);
      }
    });

    // check if no filter selected show initial list
    const noFilterSelected = Object.values(this.selectedStatusFilter).every(v => !v);

    if (noFilterSelected) {
      this.filteredVersion = this.viewVersionList;
    } else {
      this.filteredVersion = filterList;
    }
  }

  onClosePanel(event: any): void {
    this.selectedVersion = event.data;
    this.patchValue(this.selectedVersion);
    this.overlayPanel.hide();
  }

  saveNewVersion(metricId: number): void {
    if (this.editVersionForm.invalid) {
      this.editVersionForm.markAllAsTouched();
    } else {
      const startDate = this.datePipe.transform(this.editVersionForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.editVersionForm.get('endDate')?.value, 'yyyy-MM-dd');
      const versionId = this.editVersionForm.get('versionName')?.value?.contractVersionId
        ? this.editVersionForm.get('versionName')?.value?.contractVersionId
        : this.selectedVersion.contractVersionId;
      const versionName = this.editVersionForm.get('versionName')?.value;
      const statement = this.editVersionForm.get('statement')?.value;

      const payload = {
        version: versionName.version ? versionName.version : versionName,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        statement: statement || null,
      };

      this.contractService.editVersion(metricId, versionId, payload).subscribe(
        (res: any) => {
          if (res) {
            this.contractService.getCreatedContract(res);
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CONTRACTS.VERSION_EDIT_SUCCESS', {
                contract: this.viewData?.contractName,
                version: res.version,
              }),
            });
            this.resetForm();
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            err.forEach((msg: any) => {
              // errorList.push(msg.message);
              this.errorBlockService.addError(msg.message);
            });
          }
        }
      );
    }
  }

  publishVersion(metricId: number): void {
    const startDate = this.datePipe.transform(this.editVersionForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.editVersionForm.get('endDate')?.value, 'yyyy-MM-dd');
    const versionId = this.editVersionForm.get('versionName')?.value?.contractVersionId
      ? this.editVersionForm.get('versionName')?.value?.contractVersionId
      : this.selectedVersion.contractVersionId;
    const versionName = this.editVersionForm.get('versionName')?.value;
    const statement = this.editVersionForm.get('statement')?.value;

    const payload = {
      version: versionName.version ? versionName.version : versionName,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      statement: statement || null,
      contractVersionId: versionId,
    };

    this.contractService.publishVersion(metricId, payload).subscribe(
      (res: any) => {
        if (res) {
          this.contractService.getCreatedContract(res);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CONTRACTS.VERSION_PUBLISH', {
              contract: this.viewData?.contractName,
              version: res.version,
            }),
          });
          this.resetForm();
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          const errorList: any = [];

          err.forEach((msg: any) => {
            // errorList.push(msg.message);
            this.errorBlockService.addError(msg.message);
          });
          this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmPublish(event: Event): void {
    this.errorBlockService.clearErrors();

    if (!this.editVersionForm.get('startDate')?.value || !this.editVersionForm.get('endDate')?.value) {
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      this.errorBlockService.addError(msg.message);
      this.editVersionForm.markAllAsTouched();
    } else if (this.editVersionForm.invalid) {
      this.editVersionForm.markAllAsTouched();
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion(this.viewData.id);
        },
      });
    }
  }

  revokeVersion(metricId: number): void {
    this.errorBlockService.clearErrors();
    const versionId = this.editVersionForm.get('versionName')?.value?.contractVersionId
      ? this.editVersionForm.get('versionName')?.value?.contractVersionId
      : this.selectedVersion.contractVersionId;
    const versionName = this.editVersionForm.get('versionName')?.value;
    const statement = this.editVersionForm.get('statement')?.value;

    const payload = {
      version: versionName.version ? versionName.version : versionName,
      statement: statement || null,
    };

    this.contractService.revokeVersion(metricId, versionId, payload).subscribe(
      (res: any) => {
        if (res) {
          this.contractService.getCreatedContract(res);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CONTRACTS.VERSION_REVOKE', {
              contract: this.viewData?.contractName,
              version: res.version,
            }),
          });
          this.resetForm();
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          err.forEach((msg: any) => {
            // errorList.push(msg.message);
            this.errorBlockService.addError(msg.message);
          });
        }
      }
    );
  }

  cofirmRevoke(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      icon: 'pi pi-exclamation-triangle',
      message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_REVOKE'),
      acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
      rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.revokeVersion(this.viewData.id);
      },
    });
  }

  resetForm(): void {
    this.editVersionForm.reset();
    this.minEndDate = new Date();
    this.viewData = [];
    this.viewVersionList = [];
    this.errorMsgList = [];
    this.closeClicked.emit(false);
    this.errorBlockService.clearErrors();
  }

  onSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.editVersionForm.get('endDate')?.reset();
  }

  ngOnDestroy(): void {
    this.contractSubscription.unsubscribe();
  }

  private addCheckboxes(): void {
    this.statusList.map(() => {
      const control = new FormControl();

      return (this.editVersionForm.get('statusFilter') as FormArray).push(control);
    });
  }
}
