import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorBlockService {
  constructor() {}

  private errorSubject = new BehaviorSubject<string[]>([]);

  errorMessages$ = this.errorSubject.asObservable();

  addError(message: string): void {
    const currentErrors = this.errorSubject.value;

    this.errorSubject.next([...currentErrors, message]);
  }

  clearErrors(): void {
    this.errorSubject.next([]);
  }
}
