<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline">{{ formHeader }}</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <form [formGroup]="dynamicForm" *ngIf="dynamicForm?.controls">
    <div class="row mt-2">
      <ng-container *ngFor="let fields of getWebFormData">
        <!-- Text Input -->
        <ng-container *ngIf="fields.fieldType === 'String'">
          <div class="col-12" [ngClass]="{ 'd-none': fields.isHidden }">
            <label
              for="text-input"
              [ngClass]="{ required: fields.isMandatory }"
            >
              {{ fields.displayName }}
            </label>

            <span class="w-100 my-1">
              <input
                class="w-100 p-inputtext-sm"
                type="text"
                pInputText
                [formControlName]="fields.fieldName"
              />
            </span>

            <div *ngIf="dynamicForm.get(fields.fieldName)?.touched">
              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['required']"
                class="text-danger"
              >
                {{ 'VALIDATION.REQUIRED' | translate }}
              </small>

              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['maxlength']"
                class="text-danger"
              >
                {{
                  'VALIDATION.MAXLENGTH'
                    | translate : { requiredLength: fields.fieldLength }
                }}
              </small>
            </div>
          </div>
        </ng-container>

        <!-- Number Input -->
        <ng-container *ngIf="fields.fieldType === 'Number'">
          <div class="col-12" [ngClass]="{ 'd-none': fields.isHidden }">
            <label
              for="number-input"
              [ngClass]="{ required: fields.isMandatory }"
            >
              {{ fields.displayName }}
            </label>

            <span class="w-100 my-1">
              <p-inputNumber
                mode="decimal"
                [minFractionDigits]="1"
                [maxFractionDigits]="4"
                [formControlName]="fields.fieldName"
              >
              </p-inputNumber>
            </span>
            <div *ngIf="dynamicForm.get(fields.fieldName)?.touched">
              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['required']"
                class="text-danger"
              >
                {{ 'VALIDATION.REQUIRED' | translate }}
              </small>

              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['pattern']"
                class="text-danger"
              >
                {{ 'VALIDATION.INVALID_PATTERN' | translate }}
              </small>
            </div>
          </div>
        </ng-container>

        <!-- Date Input -->
        <ng-container
          *ngIf="
            ['Date and Time', 'Date', 'Month', 'Year'].includes(
              fields.fieldType
            )
          "
        >
          <div class="col-12" [ngClass]="{ 'd-none': fields.isHidden }">
            <label
              for="calendar-input"
              [ngClass]="{ required: fields.isMandatory }"
            >
              {{ fields.displayName }}
            </label>
            <span class="w-100 my-1">
              <p-calendar
                styleClass="w-100"
                type="date"
                [formControlName]="fields.fieldName"
                [showIcon]="true"
                [readonlyInput]="true"
                [view]="
                  fields.fieldType === 'Month' || fields.fieldType === 'Year'
                    ? fields.fieldType.toLowerCase()
                    : 'date'
                "
                [dateFormat]="
                  fields.fieldType === 'Month'
                    ? 'mm-yy'
                    : fields.fieldType === 'Year'
                    ? 'yy'
                    : 'dd-mm-yy'
                "
                [showTime]="fields.fieldType === 'Date and Time' ? true : false"
                [showSeconds]="true"
                appendTo="body"
              >
              </p-calendar>
            </span>
            <div *ngIf="dynamicForm.get(fields.fieldName)?.touched">
              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['required']"
                class="text-danger"
              >
                {{ 'VALIDATION.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </ng-container>

        <!-- Dropdown -->
        <ng-container *ngIf="fields.fieldType === 'Value Set'">
          <div class="col-12" [ngClass]="{ 'd-none': fields.isHidden }">
            <label for="title" [ngClass]="{ required: fields.isMandatory }">
              {{ fields.displayName }}
            </label>
            <span class="w-100 my-1">
              <p-dropdown
                styleClass="w-full"
                [formControlName]="fields.fieldName"
                [options]="valueSetList[fields.valueSetId]"
                optionLabel="title"
                optionValue="key"
              >
              </p-dropdown>
            </span>
            <div *ngIf="dynamicForm.get(fields.fieldName)?.touched">
              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['required']"
                class="text-danger"
              >
                {{ 'VALIDATION.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </ng-container>

        <!-- Tree select -->
        <ng-container *ngIf="fields.fieldType === 'Hierarchy'">
          <div class="col-12" [ngClass]="{ 'd-none': fields.isHidden }">
            <label
              for="fields.fieldName"
              [ngClass]="{ required: fields.isMandatory }"
            >
              {{ fields.displayName }}
            </label>
            <span class="w-100 my-1">
              <p-treeSelect
                [formControlName]="fields.fieldName"
                containerStyleClass="w-100 tree-select-label"
                [options]="hierarchyList"
                filterBy="name"
                [filter]="true"
                emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
              >
                <ng-template let-node pTemplate="default">
                  <div class="d-flex w-100">
                    <i
                      [ngClass]="
                        node.expanded
                          ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                          : 'pi pi-folder mr-2 my-auto tree-folder-icon'
                      "
                    ></i>
                    <div class="my-auto">
                      <h6>{{ node.name }}</h6>
                    </div>
                  </div>
                </ng-template>

                <!-- to display selected value -->
                <ng-template let-node pTemplate="value">
                  {{ node?.name ? node?.name : '' }}
                </ng-template>
              </p-treeSelect>
            </span>
            <div *ngIf="dynamicForm.get(fields.fieldName)?.touched">
              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['required']"
                class="text-danger"
              >
                {{ 'VALIDATION.REQUIRED' | translate }}
              </small>
            </div>
          </div>
        </ng-container>

        <!-- textarea -->
        <ng-container *ngIf="fields.fieldType === 'Rich Text'">
          <div class="col-12" [ngClass]="{ 'd-none': fields.isHidden }">
            <label for="textarea" [ngClass]="{ required: fields.isMandatory }"
              >{{ fields.displayName }}
            </label>
            <span class="w-100 my-1">
              <textarea
                class="w-100"
                pInputTextarea
                [formControlName]="fields.fieldName"
              ></textarea>
            </span>
            <div *ngIf="dynamicForm.get(fields.fieldName)?.touched">
              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['required']"
                class="text-danger"
              >
                {{ 'VALIDATION.REQUIRED' | translate }}
              </small>
              <small
                *ngIf="dynamicForm.get(fields.fieldName)?.errors?.['maxlength']"
                class="text-danger"
              >
                {{
                  'VALIDATION.MAXLENGTH'
                    | translate : { requiredLength: fields.fieldLength }
                }}
              </small>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- api error msg block -->
      <app-error-block></app-error-block>

      <!-- btn section -->
      <div class="d-flex justify-content-between mt-2 p-0">
        <div class="col-4 col-sm-4">
          <button
            pButton
            type="button"
            label="{{ 'MODULES.DISTRIBUTIONS.CANCEL' | translate }}"
            class="p-button-outlined"
            style="width: 110px"
            (click)="resetForm()"
          ></button>
        </div>
        <div class="col-4 col-sm-4 text-right">
          <button
            pButton
            type="submit"
            label="{{ 'MODULES.DISTRIBUTIONS.SAVE' | translate }}"
            class="p-button"
            style="width: 110px"
            (click)="submitRowForm()"
          ></button>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="!dynamicForm?.controls">
    <div class="row mt-2">
      <div class="col-12 mb-1" *ngFor="let item of [].constructor(4)">
        <p-skeleton></p-skeleton>
      </div>
    </div>
  </ng-container>
</div>
