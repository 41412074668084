import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { combineLatest, filter, Observable, Subject, takeUntil } from 'rxjs';

import { StatusConfigService } from '../../services/status-config.service';
import { CellRendererService } from '@app/core/resources/cell-renderer.service';
import { FilterParamsService } from '@app/core/resources/filter-params.service';
import { FilterTextWhitespacesService } from '@app/core/resources/filter-text-whitespaces.service';
import { FilterTypeService } from '@app/core/resources/filter-type.service';
import { PaginationAggridFlexService } from '@app/core/resources/pagination-aggrid-flex.service';
import { UploadServiceService } from '@app/data/upload/services/upload-service.service';
import { SearchService } from '@app/layout/services/search.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
  @ViewChild('agGrid', { read: ElementRef }) gridElementRef!: ElementRef;

  @Output() closeClicked = new EventEmitter<boolean>();

  headers: any = [];

  grid!: GridApi;

  tableData: any = [];

  gridOptions: GridOptions = {
    alwaysMultiSort: true,
    headerHeight: 40,
    groupHeaderHeight: 40,
    rowHeight: 40,
    suppressContextMenu: true,
    overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No records found</span>',
    defaultColDef: { resizable: true },
    autoGroupColumnDef: { minWidth: 250, resizable: true },
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 200],
    maxBlocksInCache: 0,
    cacheBlockSize: 50,
    serverSidePivotResultFieldSeparator: '_',
    pivotMode: false,
    rowModelType: 'serverSide',
    tooltipShowDelay: 0,
    onFirstDataRendered(params) {
      params.api.setGridOption('defaultColDef', { autoHeight: true });
    },
    getMainMenuItems: () => {
      return this.getMainMenuItems();
    }, // custom main column header menu
  };

  incomingSelectedTree: any;

  isUploadHistory: any = false;

  haveFilters = true;

  routeFilters: any = null; // For Global Seach handling

  private destroy$ = new Subject<void>();

  private gridReady$ = new Subject<boolean>();

  constructor(
    private uploadService: UploadServiceService,
    private statusService: StatusConfigService,
    private translateService: TranslateService,
    private cellRendererService: CellRendererService,
    private filterTextWhitespacesService: FilterTextWhitespacesService,
    private paginationService: PaginationAggridFlexService,
    private filterTypeService: FilterTypeService,
    private filterParams: FilterParamsService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.getData();
    combineLatest([
      this.gridReady$, // Emits when the grid is ready
      this.searchService.filters$, // Emits when filters are updated
    ])
      .pipe(
        takeUntil(this.destroy$), // Automatically unsubscribe on destroy
        filter(([gridReady, filters]) => gridReady && filters && filters.length > 0) // Ensuring both conditions are met
      )
      .subscribe(([_, filters]) => {
        // Apply filters and refresh the grid
        this.routeFilters = filters;
        this.refreshGridData(); // Refresh the grid with the new filters
      });
  }

  onGridReady(event: any): void {
    this.grid = event.api;
    this.gridReady$.next(true); // Signal that the grid is ready
    this.gridReady$.complete(); // Complete the observable as the grid won't "un-ready"

    // If route filters exist, apply them during initialization
    if (this.routeFilters) {
      const transformedFilters = this.transformRowGroupInfo({ filterConditions: [] });

      this.grid.setFilterModel(transformedFilters.filterConditions);
    }
    this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
  }

  refreshGridData(): void {
    if (this.grid) {
      // Force grid to re-fetch data using updated routeFilters
      this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
      this.grid.onFilterChanged(); // Trigger the fetch process
    }
  }

  getData(): void {
    this.headers = [];
    const headers = this.uploadService.getUploadDetailsHeaders() as Observable<any>;

    headers.subscribe((res: any) => {
      this.headers = res.map((item: any) => this.headerFormulate(item));
      // this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());

      const isPivotEnabled: boolean = this.headers.some((header: any) => header.pivot === true);

      this.grid?.setGridOption('pivotMode', isPivotEnabled);
    });
  }

  headerFormulate(header: any): any {
    const headerObj = {
      id: header.id,
      field: header.fieldName ? header.fieldName.toLowerCase() : header.field.toLowerCase(),
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      wrapText: header.textWrapping,
      headerName: header.displayName ? header.displayName : header.headerName,
      sortable: header.sortable,
      sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort,
      initialSortIndex: header.sortOrder ? header.sortOrder : header.initialSortIndex,
      filter: header.filterable ? this.filterTypeService.getMeFilterType(header.fieldType) : header.filter,
      filterParams: header.filterable ? this.filterParams.getMeFilterParams(header.fieldType) : header.filterParams,
      tooltipValueGetter: (params: any) => this.statusService.getMeToolTip(params.value),
      cellRenderer: (rowData: any) => {
        return this.cellRendererService.UploadCellRenderer(rowData);
      },
      enableRowGroup: header.groupRowsBy,
      rowGroup: header.groupRowsBy,
      hide: header.groupRowsBy || header.hidden,
      pivot: header.groupColumnsBy,
      enableValue: header.enableValue,
      aggFunc: header.aggFunction,
      dataTypeFormatId: header.dataTypeFormatId,
    };

    return headerObj;
  }

  getMeSorted(dirtyData: any): any {
    if (dirtyData.length > 0) {
      const sort: any = [];

      for (let i = 0; i <= dirtyData.length - 1; i -= -1) {
        sort.push(`${dirtyData[i].colId},${dirtyData[i].sort}`);
      }

      return sort;
    }

    return '';
  }

  // prevent pagination updates on sorting when no records are available
  onSortChange(): void {
    if (!this.tableData.length) {
      this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef, true);
    }
  }

  getMeFiltered(filterData: any, rowGroupInfo: any): any {
    const sqlQueries: any = [];

    Object.keys(filterData).forEach(key => {
      const filterItem = filterData[key];
      const conditions: any = [];

      const appliedFilterData: any = {
        fieldName: key,
        filterType: filterItem.filterType,
      };

      if (filterItem.operator) {
        appliedFilterData.operator = filterItem.operator;

        filterItem.conditions.forEach((item: any) => {
          const filterVal: any = {
            type: item.type,
            start: item.filterType === 'date' ? item.dateFrom : item.filter,
          };

          if (item.type === 'inRange') {
            filterVal.end = item.filterType === 'date' ? item.dateTo : item.filterTo;
          }
          conditions.push(filterVal);
        });
      } else {
        const filterVal: any = {
          type: filterItem.type,
          start: filterItem.filterType === 'date' ? filterItem.dateFrom : filterItem.filter,
        };

        if (filterItem.type === 'inRange') {
          filterVal.end = filterItem.filterType === 'date' ? filterItem.dateTo : filterItem.filterTo;
        }
        conditions.push(filterVal);
      }

      appliedFilterData.conditions = conditions;

      sqlQueries.push(appliedFilterData);
    });

    this.haveFilters =
      (this.routeFilters && this.routeFilters.length > 0) || Object.values(this.grid.getFilterModel()).length > 0; // For search and individual  filters
    const filterConditions = [...sqlQueries]; // Start with search filters
    const payload: any = filterConditions.length ? { filterConditions } : {}; // Preparing the payload for search filters
    const agParamsRequestData: any = this.transformRowGroupInfo(rowGroupInfo); // Transform row group info for additional filters

    //  Merge filters, ensuring search filters take precedence
    if (agParamsRequestData.filterConditions?.length) {
      // Append only additional filters that are not already in search filters
      const additionalFilters = agParamsRequestData.filterConditions.filter(
        (newFilter: any) =>
          !filterConditions.some((existingFilter: any) => existingFilter.fieldName === newFilter.fieldName)
      );

      filterConditions.push(...additionalFilters);
    }

    // Merge the transformed data with search filters
    const mergedPayload = {
      ...agParamsRequestData,
      ...payload,
      filterConditions, // Use the merged filterConditions
    };

    //  Remove unnecessary keys
    delete mergedPayload.startRow;
    delete mergedPayload.endRow;

    return mergedPayload;
  }

  // handling if filter only contains whitespace
  onFilterModified(e: any): void {
    this.filterTextWhitespacesService.textWhitespaceTrim(e, this.grid);
  }

  transformRowGroupInfo(rowGroupInfo: any): any {
    // Create a mapping from fieldName to an object containing id, fieldName, and aggFunc from headers
    const fieldToIdMap = this.headers.reduce((accumulator: any, header: any) => {
      accumulator[header.field] = {
        id: header.id,
        fieldName: header.field,
        aggFunction: header.aggFunc,
        formatId: header.dataTypeFormatId,
      };

      return accumulator; // Return the accumulator object for the next iteration
    }, {});

    // Transform rowGroupCols by mapping each column to its corresponding id and fieldName
    const groupByRow = rowGroupInfo.rowGroupCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      // If mapping exists, replace the id and fieldName, otherwise, return the column unchanged
      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform valueCols by mapping each column to its corresponding id and fieldName, and override aggFunc if available
    const valueCols = rowGroupInfo.valueCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? {
            ...col,
            id: mappedField.id,
            fieldName: mappedField.fieldName,
            aggFunction: mappedField.aggFunc || col.aggFunc,
            formatId: mappedField.formatId,
          }
        : col;
    });

    // Transform pivotCols
    const pivotColumns = rowGroupInfo.pivotCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform sortModel
    const sortModel = rowGroupInfo.sortModel.map((sort: any) => {
      const mappedField = fieldToIdMap[sort.colId.toLowerCase()];

      return mappedField ? { ...sort, colId: mappedField.fieldName } : sort;
    });

    // Synchronize filter conditions with routeFilters
    const filterConditions = this.routeFilters
      ? [...(rowGroupInfo.filterConditions || []), ...this.routeFilters] // Merge stored filters
      : rowGroupInfo.filterConditions;

    // Create a new object with transformed properties
    return {
      ...rowGroupInfo,
      groupByRow,
      valueCols,
      pivotColumns,
      sortModel,
      filterConditions,
    };
  }

  getGridDataFromAPI(): any {
    return {
      getRows: (agParams: any) => {
        const params: any = {
          offset: agParams.request.startRow,
          limit: agParams.request.endRow - agParams.request.startRow,
          sort: this.getMeSorted(agParams.request.sortModel),
        };

        // updating cache to be in sync with page size
        this.grid.setGridOption('cacheBlockSize', this.grid.paginationGetPageSize());

        this.uploadService
          .getUploadDetailsData(this.getMeFiltered(agParams.request.filterModel, agParams.request), params)
          .subscribe((res: any) => {
            this.tableData = res.elements;
            // handling of no data available
            if (!res.elements.length) {
              this.grid.showNoRowsOverlay();
            } else {
              this.grid.hideOverlay();
            }

            // calculating subheaders and its dataset
            if (res.reportColumnRoot && res.reportColumnRoot.length > 0) {
              const uniqueComplianceHeaders = new Set(this.headers.map((header: any) => header.field));

              res.reportColumnRoot.forEach((item: any) => {
                if (!uniqueComplianceHeaders.has(item.fieldName)) {
                  uniqueComplianceHeaders.add(item.fieldName);
                  this.headers.push(item);

                  // removing duplicate fields
                  const filteredHeaders = this.headers.filter(
                    (element: any) => element.field !== item.fieldName?.toLowerCase()
                  );

                  this.headers = filteredHeaders;
                }
              });
              this.headers = this.headers.map((header: any) => this.headerFormulate(header));
            }

            const tableData: any = res.elements.map((row: any) => {
              const rowDataEntry: any = {};
              let attributes: any = [];

              row.reportColumnData.forEach((colData: any) => {
                rowDataEntry[colData.fieldName.toLowerCase()] = colData.formattedValue;
                // Assign the value to fieldName by adding Value identifier to the key->fieldName
                rowDataEntry[`${colData.fieldName.toLowerCase()}Value`] = colData.value;

                // passing field identifier in attributes
                if (colData.reportAttributeDtos) {
                  const newAttr = colData.reportAttributeDtos?.map((v: any) => ({
                    ...v,
                    field: colData.fieldName.toLowerCase(),
                  }));

                  attributes = [...attributes, ...newAttr];
                }
                rowDataEntry.attributes = attributes;
              });

              return rowDataEntry;
            });

            agParams.success({
              rowData: tableData,
              rowCount: res.totalElements,
            });

            // auto-resize all columns by default
            // check with grouping and spanning
            agParams.columnApi.autoSizeAllColumns();
            this.routeFilters = []; // Resetting as the search navigation has been completed
            this.searchService.setFilters([]);

            // Workaround to show the actual number of rows for a given page
            this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef);
          });
      },
    };
  }

  clearGrid(): void {
    this.grid.setFilterModel(null);
    this.grid.onFilterChanged();
  }

  close(): void {
    this.isUploadHistory = false;
  }

  onCellClick(event: any): void {
    if (event.colDef.headerName === 'History') {
      // Retrieving all columns from the column API
      const metricColumns = event.columnApi.getColumns();

      // Find  the column dynamically based on a unique property (e.g., headerName or field)
      const processorId = metricColumns.find(
        (col: any) => col.colDef.headerName === 'Processor Id' // Or use another unique property like colDef.field === '371'
      );
      const sourceReport = metricColumns.find(
        (col: any) => col.colDef.headerName === 'Report' // Or use another unique property like colDef.field === '371'
      );
      const data = {
        processorid: event.data[processorId.colDef.id],
        sourcereport: event.data[sourceReport.colDef.id],
      };

      this.uploadService.getUploadViewData(data);
      this.isUploadHistory = !this.isUploadHistory;
    }
  }

  // custom main column header menu Items
  getMainMenuItems(): [] {
    const customResetCol: any = [
      'pinSubMenu',
      'separator',
      {
        name: 'Reset Columns',
        action: () => {
          this.grid.autoSizeAllColumns();
          this.grid.resetColumnState();
        },
      },
    ];

    return customResetCol;
  }

  ngOnDestroy(): void {
    if (this.grid) {
      this.grid.destroy();
    }
    // Signal the end of the component's lifecycle
    this.destroy$.next();
    this.destroy$.complete();
  }
}
