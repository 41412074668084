import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { MetricConnectorsService } from '@app/slm/services/metric-connectors.service';

@Component({
  selector: 'app-view-metric-connector',
  templateUrl: './view-metric-connector.component.html',
  styleUrls: ['./view-metric-connector.component.scss'],
})
export class ViewMetricConnectorComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  connectorSubscription!: Subscription;

  viewData: any = [];

  metricConnector: any = [];

  noDataList: any;

  constructor(private metricConnectorsService: MetricConnectorsService, private messageService: MessageService) {
    this.noDataList = [
      {
        paramName: 'N/A',
      },
    ];
  }

  ngOnInit(): void {
    this.connectorSubscription = this.metricConnectorsService.viewData.subscribe((res: any) => {
      if (res) {
        this.metricConnector = res;
        this.getMetricConnector(res.id);
      }
    });
  }

  getMetricConnector(catalogDataID: any): void {
    this.viewData = [];
    this.metricConnectorsService.getViewMetricConnector(catalogDataID).subscribe((res: any) => {
      if (res) {
        this.viewData = res;
      }
    });
  }

  closeViewMetric(): void {
    this.viewData = [];
    this.metricConnector = [];
    this.closeClicked.emit(false);
  }

  ngOnDestroy(): void {
    this.connectorSubscription.unsubscribe();
  }
}
